(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name teams.team.controller:TeamCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.teams.team')
    .controller('TeamCtrl', TeamCtrl);

  function TeamCtrl(AclService, $filter, entities, team, genders, programmes, categories, $scope, ProgrammeSports,
                    Insurances, Teams, $mdToast, $stateParams, $state, $mdDialog, sportProgramme, licenses, licenseTypes) {
    var vm = this;
    vm.ctrlName = 'TeamCtrl';

    vm.edit = false;
    vm.can = AclService.can;

    vm.team = angular.copy(team);
    vm.entities = entities;
    vm.genders = genders;
    vm.programs = programmes;
    vm.categories = categories;
    vm.sports = sportProgramme;

    vm.entitySelected = findItemById(vm.entities, vm.team.entityId);
    vm.showInsurance = false;
    vm.price = {};

    vm.printTeam = {
      entityName: vm.entitySelected.name,
      teamName: vm.team.name,
      sportName: 'SPORTS.' + findItemById(vm.sports, vm.team.sportId).name,
      categoryName: 'CATEGORIES.' + findItemById(vm.categories, vm.team.categoryId).name,
      programName: findItemById(vm.programs, vm.team.programmeId).name,
      coachName: team.coachName,
      coachEmail: team.coachEmail,
      coachPhone: team.coachPhone,
      shirtColor: team.shirtColor,
      pantsColor: team.pantsColor,
      persons: buildPersonsInformation(licenses)
    };

    vm.discardChanges = discardChanges;
    vm.updateTeam = updateTeam;


    //Buttons
    vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'Accions',
      actions: []
    };

    if (vm.can('TEAM', 'U')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: enableEdit,
          icon: 'edit',
          tooltip: $filter('translate')('EDIT')
        }
      )
    }
    if (vm.can('TEAM', 'R')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: printReport,
          icon: 'print',
          tooltip: $filter('translate')('PRINT')
        }
      )
    }
    if (vm.can('TEAM', 'D')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: deleteTeam,
          icon: 'delete',
          attrClass: 'md-warn',
          tooltip: $filter('translate')('DELETE')
        }
      )
    }

    ///// ---------

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    function enableEdit() {
      vm.edit = true;
      vm.buttons.dial = false;
      if (vm.team.inscribedToChampionship) {
        $mdDialog.show(
          $mdDialog.alert()
            .clickOutsideToClose(true)
            .title('Equip inscrit a un campionat')
            .textContent($filter('translate')('EDIT_ENROLLED'))
            .ok($filter('translate')('ACCEPT'))
        );
      }
    }

    function deleteTeam() {
      $mdDialog.show({
        controller: function DialogController($scope, $mdDialog, $filter) {
          $scope.alertText = $filter('translate')('ALERT');
          $scope.textContent = $filter('translate')('TEAMS.CONFIRM_DELETE');
          $scope.cancelText = $filter('translate')('CANCEL');
          $scope.deleteText = $filter('translate')('DELETE');

          $scope.hide = function () {
            $mdDialog.hide();
          };

          $scope.cancel = function () {
            $mdDialog.cancel();
          };

          $scope.delete = function () {
            //then
            $mdDialog.hide();
          };
        },
        templateUrl: 'home/teams/team/delete-confirmation.tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      })
        .then(function () {
          Teams.remove({id: $stateParams.teamId},
            function () {
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')('TEAMS.SUCCESSFUL_DELETE'))
                  .position('bottom left')
                  .hideDelay(3000));
              $state.go('home.teams', {}, {reload: true});
            },
            function (error) {
              var message = 'TOAST_ERROR';
              if (error.status > 0) {
                message = angular.isDefined(error.data.message)? error.data.message : message;
              }
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')(message))
                  .position('bottom left')
                  .hideDelay(3000)
              );
            });
        });
    }


    function buildPersonsInformation(licenses) {
      var persons = [];
      angular.forEach(licenses, function (license) {
        var lic = license.license;
        persons.push({
          licenseCode: license.licenseCode,
          name: lic.person.name,
          surnames: lic.person.surname1+ (lic.person.surname2 !== null ? lic.person.surname2 : ''),
          nif: lic.person.nif,
          licenseType: 'PERSON_TYPES.' + findItemById(licenseTypes, lic.licenceTypeId).name,
          category: 'CATEGORIES.' + lic.category.name,
          bornDate: moment(lic.person.bornDate).format('L')
        });
      });
      return persons;
    }

    function printReport() {
      var template = document.getElementById('teamPrintPreview').innerHTML;
      var printWin = window.open('', '',
        'left=0, top=0, width=' + screen.availWidth + ', height=' + screen.availHeight
        + ', toolbar=0, scrollbars=0, status=0');
      printWin.document.write(template);
      printWin.document.close();
      setTimeout(function () {
        printWin.focus();
        printWin.print();
        printWin.close();
      }, 500);
    }

    function getProgrammeSports(programmeId) {
      ProgrammeSports.query({id: programmeId}, function (sports) {
        vm.sports = sports;
      });
    }

    $scope.$watch('vm.team.programmeId', function (newID, oldID) {
      if (newID !== oldID) {
        if (angular.isDefined(newID)) {
          vm.team.sportId = undefined;
          getProgrammeSports(vm.team.programmeId);
        }
      }
    });

    $scope.$watch('vm.team.sportId', function (newID) {
      getInsurancePrice(vm.team.programmeId, newID, vm.team.categoryId);
    });

    $scope.$watch('vm.team.categoryId', function (newID) {
      getInsurancePrice(vm.team.programmeId, vm.team.sportId, newID);
    });

    function getInsurancePrice(programID, sportID, categoryID) {
      if (angular.isDefined(sportID) && angular.isDefined(categoryID)) {
        Insurances.get({idP: programID, idS: sportID, idC: categoryID}, function (price) {
          vm.showInsurance = true;
          vm.price = price;
        });
      } else {
        vm.showInsurance = false;
        vm.price = undefined;
      }
    }


    function discardChanges() {
      vm.team = angular.copy(team);
      vm.edit = false;
      vm.buttons.dial = true;
    }


    function updateTeam() {
      Teams.update({id: $stateParams.teamId}, vm.team, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')('TEAM_SUCCESSFUL_UPDATE'))
              .position('bottom left')
              .hideDelay(3000)
          );
          $state.go('home.teams', {}, {reload: true});
        },
        function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Hi ha hagut algun error')
              .position('bottom left')
              .hideDelay(3000)
          );
        })
    }

  }
}());
